<template>
  <h2>Success Login!</h2>  
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'GoogleSuccess',
  methods: {
    callBackURI () {
      UserService.callBackGoogle({
        code: this.$route.query.code,
        scope: this.$route.query.scope
      })
    }
  },
  created () {
    //this.callBackURI()
  }
}
</script>

<style scoped>

</style>